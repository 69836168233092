import React from "react"
import styled from "styled-components"
import GlobalStyle from "../../style"

import Header from "../Header"
import Footer from "../Footer"

const Main = styled.main`
  margin-top: 10vh;
`

const Layout = ({ children, transitionStatus, entry }) => {
  return (
    <>
      <GlobalStyle />
      <Header transitionStatus={transitionStatus} entry={entry} />
      <Main>{children}</Main>
      <Footer transitionStatus={transitionStatus} />
    </>
  )
}

export default Layout
