import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  /*background: rgba(240, 238, 238, 0.8);*/
  background: rgba(250, 250, 250, 0.8);
  width: 100%;
  backdrop-filter: blur(5px);
  z-index: 999;
`

export const Wrapper = styled.div`
  width: 90vw;
  max-width: 1024px;
  height: 10vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`

export const LogoWrapper = styled.div`
  display: block;
  width: 10%;
  min-width: 70px;
  img {
    height: 100%;
    width: 100%;
  }
`

export const NavLinks = styled.nav``

export const LinksList = styled.ul`
  display: flex;
  list-style: none;

  ${media.lessThan("medium")`
        display: none;
    `}
`

export const LinksListItem = styled.li`
  margin-left: 75px;

  &:nth-child(1) {
    margin-left: 0;
  }

  a {
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: none;
    position: relative;
  }

  a::before {
    content: "";
    width: 0;
    height: 2px;
    transform: translate(-5%);

    background: var(--primary-dark-color);

    position: absolute;
    bottom: 0;
    left: 0;

    z-index: -1;

    transition: all 300ms ease-in-out;
  }

  a:hover::before {
    width: 110%;
  }

  a.active::before {
    background: var(--primary-red-color);
    width: 110%;
  }
`
