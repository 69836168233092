import React, { useState } from "react"
import * as S from "./styled"
import TransitionLink from "gatsby-plugin-transition-link"

const Hamburger = ({ links }) => {
  const [active, setActive] = useState(false)

  const handleShow = () => {
    setActive(!active)
  }

  return (
    <S.Container>
      <S.Button onClick={handleShow}>
        <S.HamburgerIcon className={active && "active"} />
      </S.Button>
      <S.MenuModal className={active && "active"}>
        <S.LinksList>
          {links.map((link, key) => (
            <S.LinksListItem key={key}>
              <TransitionLink
                to={link.to}
                exit={{
                  length: 1,
                }}
                entry={{ length: 1, delay: 1, state: { animateHeader: false } }}
                activeClassName="active"
              >
                {link.label}
              </TransitionLink>
            </S.LinksListItem>
          ))}
        </S.LinksList>
      </S.MenuModal>
    </S.Container>
  )
}

export default Hamburger
