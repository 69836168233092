import styled, { keyframes } from "styled-components"
import media from "styled-media-query"

const slide = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Container = styled.div`
  ${media.greaterThan("medium")`
        display: none;
    `}
`

export const Button = styled.button`
  width: 35px;
  height: 35px;
  border: none;
  background: none;
`
export const HamburgerIcon = styled.span`
  display: block;
  width: 30px;
  height: 3px;
  background: var(--primary-dark-color);
  position: relative;
  z-index: 2;
  transition: all 300ms ease-in-out;

  &.active,
  &.active::after,
  &.active::before {
    background: var(--primary-light-color);
  }

  &.active {
    transform: rotate(45deg);
  }

  &.active::after {
    transform: rotate(90deg) translate(-10px);
  }

  &.active::before {
    transform: rotate(90deg) translate(-5px, -15px);
  }

  &::after,
  &::before {
    content: "";
    width: 30px;
    height: 3px;
    background: var(--primary-dark-color);
    position: absolute;
    transition: all 300ms ease-in-out;
  }

  &::after {
    bottom: -10px;
    left: 0;
    /* transform-origin: right; */
  }

  &::before {
    top: -10px;
    left: 0;
    transform-origin: left;
  }
`

export const MenuModal = styled.div`
  position: absolute;
  width: 0%;
  height: 100vh;
  background: var(--primary-dark-color);
  top: 0;
  right: 0;
  transition: all 300ms ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;

  &.active {
    width: 100%;
  }

  &.active li,
  &.active a {
    display: block;
  }
`

export const LinksList = styled.ul`
  list-style: none;
  text-align: center;
`

export const LinksListItem = styled.li`
  margin-top: 20px;

  a {
    font-weight: 900;
    font-size: 1.5em;
    text-align: center;
    color: #fff;
    display: none;
    animation: ${slide} 300ms ease-out;
    animation-delay: 250ms;
    animation-fill-mode: both;
    text-decoration: none;
  }

  a::before {
    content: "";
    width: 0;
    height: 2px;
    transform: translate(-5%);

    background: var(--primary-dark-color);

    position: absolute;
    bottom: 0;
    left: 0;

    z-index: -1;

    transition: all 300ms ease-in-out;
  }

  a.active::before {
    background: var(--primary-red-color);
    width: 110%;
  }
`
