import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Exo', sans-serif;
        color: var(--primary-dark-color);
    }

    :root {
        /*--primary-light-color: #F0EEEE;*/
        --primary-light-color: #FAFAFA;
        --secundary-light-color: #C4C4C4;
        --primary-dark-color: #272727;
        --primary-red-color: #C00000;
    }

    body {
        background: var(--primary-light-color);
    }
`

export default GlobalStyle