import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.footer`
  background: var(--primary-dark-color);
  width: 100%;
  overflow: hidden;
`
export const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  ${media.lessThan("medium")`
    flex-direction: column;
    align-items: center;
  `}

  & * {
    color: var(--primary-light-color);
  }
`

export const LogoWrapper = styled.div`
  width: 20%;
  display: flex;
  align-items: center;

  ${media.lessThan("medium")`
    width: 45%;
    margin-bottom: 50px;
  `}

  img {
    width: 100%;
  }
`

export const TextArea = styled.div`
  width: 75%;
  ${media.lessThan("medium")`
    width: 100%;
    
  `}
`

export const LinksSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.lessThan("medium")`
    flex-direction: column;
    
  `}
`

export const LinksListContainer = styled.div`
  max-width: 300px;

  ${media.lessThan("medium")`
    margin: 15px 0;
  `}
`

export const LinksList = styled.ul`
  list-style: none;
`

export const LinksListTitle = styled.h2``

export const LinksListItem = styled.li`
  margin: 15px 0;

  a {
    text-decoration: none;
    position: relative;
  }

  a::before {
    content: "";
    width: 0;
    height: 2px;
    transform: translate(-5%);

    background: var(--primary-red-color);

    position: absolute;
    bottom: 0;
    left: 0;

    transition: all 300ms ease-in-out;
  }

  a:hover::before {
    width: 110%;
  }
`

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  position: relative;
  z-index: 2;

  ${media.lessThan("medium")`
  justify-content: center;
  `}

  * {
    color: var(--primary-dark-color);
  }

  span {
    margin-left: 15px;
  }

  a {
    padding: 20px 25px;
    border: none;
    background: var(--primary-light-color);
    position: relative;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.3em;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  a::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--primary-red-color);
    right: -5px;
    bottom: -5px;
    z-index: -1;
    transition: all 300ms ease-in-out;
  }

  a:hover::before {
    right: -10px;
    bottom: -10px;
  }
`
