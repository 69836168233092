import React, { useRef, useEffect } from "react"
import * as S from "./styled"
import { StaticImage } from "gatsby-plugin-image"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap, { Power3 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

/* import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp" */

const siteMap = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "Projetos",
    to: "/projetos",
  },
  {
    label: "Sobre nós",
    to: "/sobre-nos",
  },
  {
    label: "Contato",
    to: "/contato",
  },
]

const socialLinks = [
  {
    label: "Instagram",
    to: "https://instagram.com/cross_projetos",
  },
]

const Footer = ({ transitionStatus }) => {
  gsap.registerPlugin(ScrollTrigger)
  const containerRef = useRef(null)
  const logoRef = useRef(null)
  const wppLinfRef = useRef(null)

  const linksRef = useRef([])

  linksRef.current = []

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { ease: Power3.easeOut },
      scrollTrigger: containerRef.current,
      onStart: () => {
        linksRef.current.forEach(item => {
          gsap.from(item.children[1].children, {
            y: 100,
            opacity: 0,
            delay: 2,
            stagger: 0.1,
          })
        })
      },
    })

    tl.from(containerRef.current, {
      width: 0,
      duration: 1,
    })
      .from(logoRef.current, {
        opacity: 0,
      })
      .from(linksRef.current, {
        y: 50,
        opacity: 0,
        stagger: 0.2,
      })
      .from(wppLinfRef.current, { y: 200, opacity: 0 })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(containerRef.current, {
        opacity: 0,
      })
    }
  }, [transitionStatus])

  const addToRef = el => {
    if (el && !linksRef.current.includes(el)) {
      linksRef.current.push(el)
    }
  }

  return (
    <S.Container ref={containerRef}>
      <S.Wrapper>
        <S.LogoWrapper ref={logoRef}>
          <StaticImage src="../../images/white-logo.png" />
        </S.LogoWrapper>
        <S.TextArea>
          <S.LinksSection>
            <S.LinksListContainer ref={addToRef}>
              <S.LinksListTitle>Nosso site</S.LinksListTitle>
              <S.LinksList>
                {siteMap.map((link, key) => (
                  <S.LinksListItem key={key}>
                    <TransitionLink to={link.to}>{link.label}</TransitionLink>
                  </S.LinksListItem>
                ))}
              </S.LinksList>
            </S.LinksListContainer>
            <S.LinksListContainer ref={addToRef}>
              <S.LinksListTitle>Faça uma visita</S.LinksListTitle>
              <S.LinksList>
                <S.LinksListItem>
                  O endereço é Av. Padre Anchieta, <br />
                  5086. Loja 2. Jd. Marcia. Peruíbe - SP
                </S.LinksListItem>
                <S.LinksListItem>crosseng@uol.com.br</S.LinksListItem>
              </S.LinksList>
            </S.LinksListContainer>
            <S.LinksListContainer ref={addToRef}>
              <S.LinksListTitle>Siga-nos</S.LinksListTitle>
              <S.LinksList>
                {socialLinks.map((link, key) => (
                  <S.LinksListItem key={key}>
                    <a href={link.to}>{link.label}</a>
                  </S.LinksListItem>
                ))}
              </S.LinksList>
            </S.LinksListContainer>
          </S.LinksSection>
          <S.ButtonSection ref={wppLinfRef}>
            {/* <a href="#">
              <Whatsapp size="30" />
              <span>Fale no whatsapp</span>
            </a> */}
          </S.ButtonSection>
        </S.TextArea>
      </S.Wrapper>
    </S.Container>
  )
}

export default Footer
