import React, { useRef, useEffect } from "react"
import {
  Container,
  Wrapper,
  LogoWrapper,
  LinksList,
  LinksListItem,
} from "./styled"
import { StaticImage } from "gatsby-plugin-image"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"

import Hamburger from "../HamburgerMenu"

const internalLinks = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "Projetos",
    to: "/projetos",
  },
  {
    label: "Sobre nós",
    to: "/sobre-nos",
  },
  {
    label: "Contato",
    to: "/contato",
  },
]

const Header = ({ transitionStatus, entry }) => {
  const headerRef = useRef(null)

  useEffect(() => {
    if (entry.state.animateHeader === undefined) {
      gsap.from(headerRef.current, {
        y: "-100%",
        delay: 1,
        duration: 1,
        ease: "power1.out",
      })
    }
  }, [entry.state.animateHeader])

  return (
    <Container ref={headerRef}>
      <Wrapper>
        <LogoWrapper>
          <StaticImage src="../../images/logo.png" />
        </LogoWrapper>
        <LinksList>
          {internalLinks.map((link, key) => (
            <LinksListItem key={key}>
              <TransitionLink
                activeClassName="active"
                to={link.to}
                exit={{
                  length: 1,
                }}
                entry={{ length: 1, delay: 1, state: { animateHeader: false } }}
              >
                {link.label}
              </TransitionLink>
            </LinksListItem>
          ))}
        </LinksList>
        <Hamburger links={internalLinks} />
      </Wrapper>
    </Container>
  )
}

export default Header
